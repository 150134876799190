import styled from 'styled-components'
import { Link } from 'react-router-dom';
import { screensize } from '../ScreenSize';


export const PartyStyledLink = styled(Link)`
    all: unset;
    cursor: pointer;
    padding: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: ${screensize.phone}) {
        padding: 10px 0;
        width: calc(50% - 45px);
    }
`

export const Urlsul = styled.ul`
    display: flex;
    list-style: none;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    padding: 0 12vw;

    @media screen and (max-width: ${screensize.phone}) {
        padding: 0px;
        width: 100%;
        flex-wrap: wrap;
    }
`

export const Urlsli = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: ${screensize.phone}) {
        width: calc(50% - 45px);
    }

`
export const PartyHeaderImg = styled.img`
  width: 120px;

  @media screen and (max-width: ${screensize.phone}) {
    width: 90px;
  }
`

export const PartyHeadA = styled.a`
    all: unset;
    cursor: pointer;
    padding: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: ${screensize.phone}) {
        width: 100%;
        padding: 10px 0;
    }
`

export const PartyHeaderALast = styled.a`
    all: unset;
    cursor: pointer;
    padding: 30px 30px 30px 10px;

    @media screen and (max-width: ${screensize.phone}) {
        padding: 10px 0 10px 10px;
    }
`