import { ContactStyledIconTelegram, ContactsSection, ContactsH, ContactsP, ContactsHh, ContactsUl, ContactsLi, ContactsA, ContactsPc } from "./Contacts.styled"
import { FaTelegramPlane, FaWhatsapp, FaHandPointDown } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";

export const Contacts = () => {
    return(
        <ContactsSection>
            {/* <ContactsH>Как подать заявку на участие в вечеринке?</ContactsH>
            <ContactsP>Сделай фото, видео, укажи пункты о себе и отправь их в наш с тобой чат</ContactsP>
            <ContactsP>Остались вопросы? Пиши в телеграм.<FaHandPointDown/></ContactsP> */}
            <div>
                <ContactsUl>
                    <ContactsLi>
                        <ContactsA href="https://t.me/Manaqer_Banni" target="_blank">
                            <ContactStyledIconTelegram/>
                            <ContactsPc>Подать заявку</ContactsPc>
                        </ContactsA>
                    </ContactsLi>
                    {/* <ContactsLi>
                        <ContactsA href="https://wa.me/your_whatsapp_number" target="_blank">
                            <IoLogoWhatsapp />
                            <ContactsPc>WhatsApp</ContactsPc>
                        </ContactsA>
                    </ContactsLi>
                    <ContactsLi>
                        <ContactsA href="mailto:your_email@example.com">
                            <MdEmail />
                            <ContactsPc>your_email@example.com</ContactsPc>
                        </ContactsA>
                    </ContactsLi> */}
                </ContactsUl>
            </div>
        </ContactsSection>
    )
}