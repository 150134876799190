import styled from "styled-components";
import {screensize} from '../ScreenSize';

export const SectionTwoSection = styled.section`
    padding: 20px 12vw 0 12vw;
    margin: 0 auto;

    
    @media screen and (max-width: ${screensize.phone}) {
        padding: 20px 6vw 0 6vw;
    }
`

export const SectionTwoDiv = styled.div`
    background-color: white;
    padding: 20px 0;
    margin: 20px 0;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

export const SectionTwoP = styled.p`

    

`

export const SectionTwoDivImg = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    margin: 20px 0;

    @media screen and (max-width: ${screensize.phone}) {
       flex-wrap: wrap;
       justify-content: center;
    }
`

export const SectionTwoImg = styled.img`
    width: 100%;
    
    margin: 4px 20px 4px 30px;

    @media screen and (min-width: ${screensize.phone}) {
        width: 360px;
    }
    @media screen and (min-width: ${screensize.laptop}) {
        width: 390px;
    }
    @media screen and (min-width: ${screensize.desktopS}) {
        max-width: 460px;
    }
`

export const SectionTwoImgP = styled.p`
    font-style: italic;
    color: gray;
`
