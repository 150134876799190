import styled from "styled-components";
import {screensize} from '../ScreenSize';

export const StepsSection = styled.section`
    padding: 90px 12vw 0 12vw; 

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: ${screensize.phone}) {
        padding: 90px 6vw 0 6vw; 
    }  
`

export const StepsH = styled.h4`
    font-size: 28px;
`

export const StepsUl = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;

`

export const StepsLi = styled.li`
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: nowrap;
    width: 300px;
    margin: 0;

    &:nth-child(2) {
        margin: 0 auto;
    }
    &:nth-child(5) {
        margin: 0 auto;
    }

    @media screen and (min-width: 1482px) {
        &:nth-child(2) {
            margin: 0 10%;
        }
        &:nth-child(5) {
            margin: 0 10%;
        }
    }

    @media screen and (max-width: ${screensize.desktopS}) {
        &:nth-child(odd) {
            margin: 0 auto 0 20px;
        }
        &:nth-child(even) {
            margin-right: 20px;
        }
    }
        
    @media screen and (max-width: 860px) {
        &:nth-child(odd) {
            margin: 0 auto 0 auto;
        }
        &:nth-child(even) {
            margin: 0 auto 0 auto;
        }
    }  
`

export const StepsP = styled.p`
    padding-left: 20px;
`

export const StepsStrong = styled.strong`

`