import { Urlsli, Urlsul, HeaderA, StyledLink, HeaderALast, HeaderImg, HeaderButton } from "./Header.styled"
import { Link } from 'react-router-dom';
import { FaTelegramPlane } from "react-icons/fa";
import logo from '../../logo.svg';
import { screensize } from '../ScreenSize';

export const Header = ({scrollToSection, about, steps, party}) => {
    const isPhoneSize = window.innerWidth <= parseInt(screensize.phone);

    return (
        <Urlsul>
            {isPhoneSize ? (
                <>
                    <StyledLink to="/party">Тусовки</StyledLink>                             
                    <HeaderImg src={logo} />
                    <Urlsli><HeaderA onClick={() => scrollToSection(steps)}>6 шагов</HeaderA></Urlsli>

                    <Urlsli><HeaderA onClick={() => scrollToSection(about)}>Об агенстве</HeaderA></Urlsli>
                    <Urlsli>
                        <FaTelegramPlane />
                        <HeaderALast href="https://t.me/Manaqer_Banni">Связаться с нами</HeaderALast>
                    </Urlsli>
                    

                </>
            ) : (
                <>
                    <StyledLink to="/party"><Urlsli>Тусовки</Urlsli></StyledLink>
                    <Urlsli><HeaderA to='/' onClick={() => scrollToSection(about)}>Об агенстве</HeaderA></Urlsli>
                    <HeaderImg src={logo} />
                    <Urlsli><HeaderA onClick={() => scrollToSection(steps)}>6 шагов</HeaderA></Urlsli>
                    <Urlsli>
                        <FaTelegramPlane />
                        <HeaderALast href="https://t.me/Manaqer_Banni">Связаться с нами</HeaderALast>
                    </Urlsli>
                </>
            )}
        </Urlsul>
    )
}
