import React, { forwardRef } from 'react';
import { FaCheck } from "react-icons/fa6";
import { QuestionsSection, QuestionsUl, QuestionsLi, QuestionsDiv, QuestionsH, QuestionsP, QuestionsIcon } from './Questions.styled';


export const Questions = forwardRef((props, ref) => {
    return(
        <QuestionsSection ref={ref}>
            <QuestionsUl>
                <QuestionsLi>
                    <QuestionsIcon/>
                    <QuestionsDiv>
                        <QuestionsH>Как обеспечивается безопасность девушек?</QuestionsH>
                        <QuestionsP>В сфере модельных мероприятий для высокопоставленных клиентов в ОАЭ безопасность девушек всегда на первом месте. Перед каждой поездкой заранее обсуждаются все условия, и заказчики обычно являются шейхами, принцами или королями, которые уже зарекомендовали себя годами.
                            <br/><br/>
                            Шейхи относятся к девушкам с уважением и никогда не требуют от них ничего неприемлемого. Такие люди, как правило, не создают проблем, поскольку они публичные личности, а скандалы невыгодны никому.
                            <br/><br/>
                            Хотя среди заказчиков могут встречаться те, кто проявляет необычные предпочтения, модели всегда информируются заранее о таких особенностях, и им задаются вопросы о готовности работать с такими людьми. Некоторые модели соглашаются работать с такими заказчиками, поскольку получают за это значительно более высокое вознаграждение, чем на обычных мероприятиях.
                            <br/><br/>
                            Некоторые профессионалы в этой области предпочитают не сотрудничать с такими заказчиками, чтобы обезопасить своих моделей и сохранить свою репутацию.
                        </QuestionsP>
                    </QuestionsDiv>
                </QuestionsLi>
                <QuestionsLi>
                    <QuestionsIcon/>
                    <QuestionsDiv>
                        <QuestionsH>Забирают ли паспорта на мероприятиях?</QuestionsH>
                        <QuestionsP>
                            Как правило, на модельных тусовках в ОАЭ паспорта не забирают, а лишь просят показать их, чтобы удостовериться в возрасте гостя или сделать фотографию для покупки билетов. В некоторых случаях могут забрать телефоны гостей на время мероприятия, но взамен дать телефон с неработающей камерой, чтобы предотвратить съемку и фотографирование. Однако гости могут использовать свои телефоны на отдельной вилле, где они проживают с другими моделями.
                        </QuestionsP>
                    </QuestionsDiv>
                </QuestionsLi>
                <QuestionsLi>
                    <QuestionsIcon/>
                    <QuestionsDiv>
                        <QuestionsH>Будут ли расходы на поездку?</QuestionsH>
                        <QuestionsP>
                            При сотрудничестве с агентством модели не обязаны оплачивать билеты или визы заранее — все эти расходы берет на себя агентство. Стандартная комиссия за работу составляет 30%, и она оплачивается наличными долларами по завершении мероприятия.
                        </QuestionsP>
                    </QuestionsDiv>
                </QuestionsLi>
                <QuestionsLi>
                    <QuestionsIcon/>
                    <QuestionsDiv>
                        <QuestionsH>Как обеспечивается конфиденциальность моделей?</QuestionsH>
                        <QuestionsP>
                            Модели могут быть уверены, что их личные данные останутся под защитой, поскольку менеджеры строго соблюдают меры безопасности, чтобы предотвратить утечку информации. Репутация менеджера также зависит от этого, так как он заинтересован в продолжении работы в этой области. Моделей просят сохранять конфиденциальность и не распространять свои анкеты среди посторонних. Все заказчики объединены в единую базу данных, и важно набраться терпения, чтобы получить лучшие условия для работы.
                        </QuestionsP>
                    </QuestionsDiv>
                </QuestionsLi>
            </QuestionsUl>
        </QuestionsSection>
    )});