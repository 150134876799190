import styled from "styled-components";
import {screensize} from '../ScreenSize';

export const CardsSection = styled.section`
  padding: 80px 12vw 10px 12vw; 

  @media screen and (max-width: ${screensize.phone}) {
    padding: 140px 6vw 10px 6vw; 
  }  
`

export const CardsUl = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    max-width: 800px;
    margin: 0 auto;   
    padding: 0;    
`

export const CardsLi = styled.li`
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  margin: 10px 0;
    
  width: 100%  

  @media screen and (max-width: ${screensize.phone}) {
    padding: 12px 0;
 } 
`

export const CardsImg = styled.img`
  width: 300px;
  height: 200px;
  object-fit: cover;
  

  @media screen and (max-width: ${screensize.phone}) {
    width: 100%;
    height: 150px;
 }  
`

export const CardsDiv = styled.div`
  padding: 20px;
  flex: 1;

  @media screen and (max-width: ${screensize.phone}) {
    padding: 14px 20px;
  }    
`

export const CardsH = styled.h4`
  font-size: 1.5rem;
  margin: 6px 0 6px 0;   
`

export const CardsP = styled.p`
  margin-bottom: 10px;
  color: #555;  
  margin: 12px 0 5px 0;  

  @media screen and (max-width: ${screensize.phone}) {
    margin: 10px 0 4px 0;
  }
  
  @media screen and (min-width: 1440px){
    font-size: 18px;
    line-height: 27px;
  }
`