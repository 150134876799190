import { AboutSection, AboutDiv, AboutH, AboutP } from "./About.styled";
import React, { forwardRef } from 'react';

export const About = forwardRef((props, ref) => {
    return(
        <AboutSection ref={ref}>
            <AboutDiv>
                <AboutH>Об агенстве</AboutH>
                <AboutP>
                    Агентство BANNI – это ваш надежный партнер в мире тусовок и вечеринок. <br/>
                    Мы стремимся объединить талантливых моделей с уникальными возможностями для их самореализации. <br/>
                    Наша команда состоит из опытных профессионалов, которые готовы помочь вам раскрыть свой потенциал и достичь успеха в модельной карьере.<br/>
                    <br/>
                    Мы приглашаем моделей различных стилей и типажей, которые хотят стать частью динамичного мира вечеринок. <br/>
                    В "Модельных тусовках" вы получите не только шанс участвовать в интересных заездах, но и поддержку на каждом этапе вашего пути.<br/>
                    <br/>
                    Мы гордимся тем, что предоставляем безопасную и профессиональную среду, где каждый может развиваться и находить новые возможности. <br/>
                    Наша репутация основана на доверии и уважении к каждому участнику нашего агентства. <br/>
                    Мы стремимся к долгосрочным отношениям, поэтому ваша уверенность и комфорт для нас на первом месте.<br/>
                    <br/>
                    Если вы хотите стать частью нашей команды и открыть для себя мир бесконечных возможностей, подайте заявку нам в телеграм. Мы ждем именно вас!
                </AboutP>
            </AboutDiv>
        </AboutSection>
    )});