import logo from './logo.svg';
import './App.css';
import Party from './pages/Party';
import styled from 'styled-components';
import React, { useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';


import { Header } from './components/Header/Header';
import { SectionOne } from './components/SectionOne/SectionOne';
import { PreView } from './components/PreView/PreView';
import { Cards } from './components/Cards/Cards';
import { Steps } from './components/Steps/Steps'
import { Contacts } from './components/Contacts/Contacts';


import { PartyHeader } from './components/PartyHead/PartyHead';
import { PartySectionOne } from './components/PartySectionOne/PartySectionOne';
import { PartySectionTwo } from './components/PartySectionTwo/PartySectionTwo';
import { PartyCards } from './components/PartyCards/PartyCards';
import { About } from './components/About/About';
import { Questions } from './components/Questions/Questions';


function App() {
  const about = useRef(null);
  const steps = useRef(null);
  const party = useRef(null);
  const whatis = useRef(null);
  const questions = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const scrollToSection = (ref, path) => {
    console.log(location.pathname, '|', path)
    console.log(location.pathname !== path);
    if (location.pathname !== path) {
      
      // Перенаправляем на нужную страницу и прокручиваем после загрузки
      navigate(path);
      setTimeout(() => {
        if (ref && ref.current) {
          ref.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // Задержка для загрузки
    } else if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <Header scrollToSection={scrollToSection} about={about} steps={steps} party={party} />
              <SectionOne />
              <PreView />
              <Cards scrollToSection={scrollToSection} party={party} whatis={whatis} questions={questions}/>
              <About ref={about} />
              <Steps ref={steps} />
              <Contacts />
            </div>
          }
        />
        <Route
          path="/party"
          element={
            <div>
              <PartyHeader scrollToSection={scrollToSection} about={about} steps={steps}/>
              <PartySectionOne />
              <PreView />
              <PartySectionTwo ref={whatis}/>
              <Questions ref={questions}/>
              <PartyCards ref={party} />
              <Contacts />
            </div>
          }
        />
      </Routes>
    </div>
  );
}

export default function MainApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}

// function App() {
//   const about = useRef(null);
//   const steps = useRef(null);
//   const party = useRef(null);

//   // const scrollToSection = (ref) => {
//   //   ref.current.scrollIntoView({ behavior: 'smooth' });
//   // };

//   const navigate = useNavigate();
//   const location = useLocation();
  
//   const scrollToSection = (ref, path = '/') => {
//     if (location.pathname !== path) {
//       // Перенаправляем на нужную страницу и прокручиваем после загрузки
//       navigate(path);
//       setTimeout(() => {
//         if (ref && ref.current) {
//           ref.current.scrollIntoView({ behavior: 'smooth' });
//         }
//       }, 100); // Задержка, чтобы дать время странице загрузиться
//     } else if (ref && ref.current) {
//       ref.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   return (

  
//   <Router>
//   {/* <div> */}
//     <Routes>
//       <Route path="/" element={
//         <div>
//           <Header scrollToSection={scrollToSection} about={about} steps={steps} party={party}/>
//           <SectionOne />
//           <PreView />
//           <Cards scrollToSection={scrollToSection} party={party}/>
//           <About ref={about}/>
//           <Steps ref={steps}/>
//           <Contacts />
//         </div>
//       } />
//       <Route path="/party" element={
//         <div>
//           <PartyHeader />
//           <PartySectionOne />
//           <PreView />
//           <PartySectionTwo/>
//           <PartyCards ref={party}/>
//           <Contacts />
//         </div>
//         } />
//     </Routes>
//   {/* </div> */}
// </Router>  
// )
// }

// export default App;

