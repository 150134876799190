import styled from "styled-components";
import {screensize} from '../ScreenSize';

export const PreViewSection = styled.section`
    display: flex;
    justify-content: space-around; /* Равномерное распределение блоков */
    align-items: center; /* Выравнивание по вертикали */
    background-color: #e5e5e5; /* Цвет фона */
    padding: 80px 12vw 90px 12vw;
    border-top: 1px solid #ccc; /* Верхняя граница */
    border-bottom: 1px solid #ccc; /* Нижняя граница */
    border: none;

    @media screen and (max-width: ${screensize.phone}) {
        display: flex;
        flex-wrap: wrap;
        padding: 80px 6vw 90px 6vw;
    }
    @media screen and (max-width: ${screensize.laptop}) {
        padding: 80px 8vw 70px 8vw;
    }
`

export const PreViewH = styled.h4`
    padding: 0 0 12px 0;
    margin: 0;

    width: 100%;
    font-size: 2rem;
    border-bottom: 1px solid #ccc; /* Линия между блоками */
    text-align: center;
`

export const PreViewP = styled.p`
    padding: 12px 0 0 0;
    margin: 0;

    font-size: 1rem;
    color: #555; /* Цвет текста */
    text-align: center;

    @media screen and (max-width: ${screensize.laptop}) {
        height: 40px;
    }
`

export const PreViewDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 270px;

    height: 180px;

    @media screen and (max-width: ${screensize.phone}) {
        height: 140px;
        width: 100%;
    }
    @media screen and (max-width: ${screensize.laptop}) {
        width: 180px;
    }
`