import styled from 'styled-components'
import { Link } from 'react-router-dom';
import {screensize} from '../ScreenSize';

export const CardsSection = styled.section`
    margin: 0;
    padding: 0;
    border: none;
    padding: 80px 12vw 0 12vw;

    @media screen and (max-width: ${screensize.phone}) {
        padding: 80px 6vw 0 6vw;
    }
    @media screen and (max-width: ${screensize.laptop}) {
        padding: 80px 8vw 0 8vw;
    }
`

export const UlCard = styled.ul`
    margin-top: 40px;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 0;

    @media screen and (max-width: ${screensize.phone}) {
        margin-top: 20px;
    }


`

export const LiCard = styled.li`
    width: 560px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px; 
    overflow: hidden; 
    background-color: #fff; 
    transition: transform 0.3s ease; 
    cursor: pointer;
    height: 650px;

    @media screen and (max-width: ${screensize.phone}) {
        height: 580px;
    }

    @media screen and (min-width: 1514px) {
        &:nth-child(3) {
            transform: translateX(-295px);;
        }
    }

`

export const ACard = styled(Link)`
    all: unset;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;

`

export const TitleCard = styled.h3`
    padding: 0 20px;
`

export const ImgCard = styled.img`
    width: 100%;
    height: 364px;
    object-fit: cover;

`

export const TextCard = styled.p`
    padding: 0 20px;
    margin: 0;

`

export const ButtonCard = styled.p`
    text-align: right;
    margin: auto 0 0 0;
    padding: 0 20px 20px 0;
    font-weight: bold;
    color: #007BFF;
`

export const CardButton = styled.button`
    all:unset;
`