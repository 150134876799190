import styled from 'styled-components'
import background from '../imgs/imgMain.webp'
import {screensize} from '../ScreenSize';

export const SectionMainSection = styled.section`
    padding: 0;
    margin: 0;
    border: none;
`

export const FigureSection = styled.figure`
    background-image:url(${background});
    height: 560px;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    background-size: cover;
    background-position: center;
    
`

export const TitleSection = styled.h2`
    font-size: 50px;
    text-align: center;

    @media screen and (max-width: ${screensize.phone}) {
        font-size: 32px;
        line-height: 42px;
    }
    @media screen and (max-width: ${screensize.laptop}) {
        font-size: 38px;
        line-height: 57px;
        max-width: 500px

    }
`

export const InfoSection = styled.p`
    font-size: 28px;
    text-align: center;
    padding: 0 12vw;


    @media screen and (max-width: ${screensize.laptop}) {
        font-size: 20px;
        line-height: 30px;
        padding: 0 8vw;
    }
    @media screen and (max-width: ${screensize.phone}) {
        font-size: 18px;
        line-height: 27px;
        padding: 0 6vw;
    }
`

export const FigcaptionSection = styled.figcaption`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    height: 100%;
    width: 100%;

    color: white;
    background-color: rgba(0, 0, 0, 0.5);
`
