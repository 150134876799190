import li1 from '../imgs/party.jpg'
import li2 from '../imgs/li2.jpeg'
import li3 from '../imgs/li3.jpeg'

import { UlCard, LiCard, ACard, ImgCard, TitleCard, TextCard, ButtonCard, CardsSection, CardButton } from './Cards.styled'

export const Cards = ({scrollToSection, party, whatis, questions}) => {
    return (
        <CardsSection>
        <UlCard>
            <LiCard>
                <ACard to='/party' onClick={() => scrollToSection(party, '/party')}>
                    <ImgCard src={li1}/>
                    <TitleCard>Модельные тусовки в ОАЭ</TitleCard>
                    <TextCard>
                    Одним из самых престижных и популярных способов заработка для привлекательных и молодых девушек является работа моделью на вечеринках в ОАЭ.
                    </TextCard>
                    <ButtonCard>Подробнее</ButtonCard>
                </ACard>
            </LiCard>
            <LiCard>
                <ACard to='/party' onClick={() => scrollToSection(whatis, '/party')}>
                    <ImgCard src={li2}/>
                    <TitleCard>Модельные тусовки - Что это ?</TitleCard>
                    <TextCard>
                        Путешествуйте, наслаждайтесь отдыхом и зарабатывайте достойные гонорары, принимая участие в элитных модельных вечеринках, организованных влиятельными и состоятельными людьми. 
                    </TextCard>
                    <ButtonCard>Подробнее</ButtonCard>
                </ACard>
            </LiCard>
            <LiCard>
                <ACard to='/party' onClick={() => scrollToSection(questions, '/party')}>
                    <ImgCard src={li3}/>
                    <TitleCard>Частые вопросы про модельные тусовки</TitleCard>
                    <TextCard>
                    Часто задаваемые вопросы о модельных мероприятиях: меры безопасности для девушек, необходимость сдачи паспортов и телефонов, условия участия и другие важные детали. 
                    </TextCard>
                    <ButtonCard>Подробнее</ButtonCard>
                </ACard>
            </LiCard>
        </UlCard>
        </CardsSection>
    )
}