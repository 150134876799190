import { CardsSection, CardsUl, CardsLi, CardsImg, CardsDiv, CardsH, CardsP } from "../PartyCards/PartyCards.styled"
import one from '../imgs/1.jpg'
import two from '../imgs/2.jpg'
import three from '../imgs/3.jpg'
import four from '../imgs/4.jpg'
import five from '../imgs/5.jpg'
import six from '../imgs/6.jpg'
import seven from '../imgs/7.jpg'
import React, { forwardRef } from 'react';

export const PartyCards = forwardRef((props, ref) => {
    return(
        <CardsSection ref={ref}>
            <CardsUl>
                <CardsLi>
                    <CardsImg src={one} alt="Принц Дубая"/>
                    <CardsDiv>
                        <CardsH>Принц Дубая Фазза</CardsH>
                        <CardsP><strong>Оплата:</strong> $7000 чистыми за неделю.</CardsP>
                        <CardsP>Большой плюс данной тусовки, что это чисто ИМИДЖ поездка на 7 дней!</CardsP>
                    </CardsDiv>
                </CardsLi>
                <CardsLi>
                    <CardsImg src={two} alt="Принц Дубая"/>
                    <CardsDiv>
                        <CardsH>Король Мухаммед Абу-Даби</CardsH>
                        <CardsP><strong>Оплата:</strong> $1000 в день - %, выходит около $18 000-$100 000, возможен прод - 2000 - 3000 $ + дорогие подарки (часы, украшения).</CardsP>
                        <CardsP>Тусовка с возможным прод. от 1 до 6 месяцев</CardsP>
                    </CardsDiv>
                </CardsLi>
                <CardsLi>
                    <CardsImg src={three} alt="Принц Дубая"/>
                    <CardsDiv>
                        <CardsH>Принц Сауди</CardsH>
                        <CardsP><strong>Оплата:</strong> $3000 в день</CardsP>
                        <CardsP>Длительность: от 1 до 6 месяцев
Местоположение: ОАЭ, Эр-Рияд
Одна из самых дорогих и прибыльных модельных тусовок.</CardsP>
                    </CardsDiv>
                </CardsLi>
                <CardsLi>
                    <CardsImg src={four} alt="Принц Дубая"/>
                    <CardsDiv>
                        <CardsH>Принц Бахрейна</CardsH>
                        <CardsP>Длительность от 3 дней до 2 недель.
                        Проживание на вилле, где есть все необходимое для комфортного проживания.</CardsP>
                    </CardsDiv>
                </CardsLi>
                <CardsLi>
                    <CardsImg src={five} alt="Принц Дубая"/>
                    <CardsDiv>
                        <CardsH>Шейх Мурад</CardsH>
                        <CardsP><strong>Оплата:</strong> $600 в день (чистыми), выходит $18 000 - $ 54 000 за заезд.</CardsP>
                        <CardsP>Тусовка с возможным продолжением от 1 до 3 месяцев.</CardsP>
                    </CardsDiv>
                </CardsLi>
                <CardsLi>
                    <CardsImg src={six} alt="Принц Дубая"/>
                    <CardsDiv>
                        <CardsH>Мансур</CardsH>
                        <CardsP><strong>Оплата:</strong> $1500 в день -30% комиссия, прод $2000, шоппинг от $2000</CardsP>
                        <CardsP>Тусовка в Абу-Даби 3-14 дней</CardsP>
                    </CardsDiv>
                </CardsLi>
                <CardsLi>
                    <CardsImg src={seven} alt="Принц Дубая"/>
                    <CardsDiv>
                        <CardsH>Шейх Наян (Ханис)</CardsH>
                        <CardsP><strong>Оплата:</strong> $1000 в день -30% комиссия, в карантин $500 в день, $2000-5000 прод (оч. редко).</CardsP>
                        <CardsP>Продолжительность в среднем 3 недели (из них 4-5 дней карантин)</CardsP>
                    </CardsDiv>
                </CardsLi>
            </CardsUl>
        </CardsSection>
    )
});