import { SectionTwoSection, SectionTwoDiv, SectionTwoDivImg, SectionTwoImg, SectionTwoImgP } from "./PartySectionTwo.styled"
import bokalparty from '../imgs/party-bokal.jpg'
import React, { forwardRef } from 'react';

export const PartySectionTwo = forwardRef((props, ref) => {
    return(
        <SectionTwoSection ref={ref}>
            <SectionTwoDiv>
                <p>
                    Один из самых популярных и престижных способов заработка для молодых и привлекательных девушек — стать моделью для мероприятий в ОАЭ. Эта работа требует не только внешних данных, но и определенных навыков, а также способности работать в конкурентной среде.
                </p>
                <p>
                    Основными требованиями для моделей на таких мероприятиях являются физическая привлекательность, здоровый образ жизни, вежливость и пунктуальность. Также важно умение общаться с людьми и знание светского этикета.
                </p>
                <SectionTwoDivImg>
                    <SectionTwoImgP>Денежное вознаграждение может варьироваться от 800 до 3000 долларов в сутки.</SectionTwoImgP>
                    <SectionTwoImg src={bokalparty} alt="Шампанское"/>
                </SectionTwoDivImg>
                <p>
                    Задачи модели на таких тусовках включают встречу гостей, участие в танцах и играх, общение с клиентами и создание праздничной атмосферы. Обычно работа длится 3-5 часов вечером, и оплата зависит от условий конкретного заезда.
                </p>
                <p>
                    Условия работы могут различаться, но чаще всего работодатель предоставляет бесплатное жилье и оплачивает билеты. Зарплата может варьироваться от 800 до 3000 долларов в день.                </p>
                <p>
                    Работа моделью на таких тусовках в ОАЭ — это отличный способ заработать и получить опыт работы в роскошной и эксклюзивной обстановке.                
                </p>
            </SectionTwoDiv>
        </SectionTwoSection>
    )
})