import { PreViewSection, PreViewDiv, PreViewH, PreViewP } from "./PreView.styled"

export const PreView = () => {
    return(
    <PreViewSection>
        <PreViewDiv>
            <PreViewH>~ 10 000 $</PreViewH>
            <PreViewP>от 10 000$ за поездку и выше</PreViewP>
        </PreViewDiv>
        <PreViewDiv>
            <PreViewH>5 дней</PreViewH>
            <PreViewP>Длительность в среднем от 5 дней</PreViewP>
        </PreViewDiv>
        <PreViewDiv>
            <PreViewH>Возраст</PreViewH>
            <PreViewP>от 18 до 25 лет</PreViewP>
        </PreViewDiv>
    </PreViewSection>
    )
}