import styled from "styled-components";
import {screensize} from '../ScreenSize';

export const AboutSection = styled.section`
    padding: 90px 12vw 90px 12vw;

    @media screen and (max-width: ${screensize.laptop}) {
        padding: 90px 8vw 90px 8vw;
    }

    @media screen and (max-width: ${screensize.phone}) {
        padding: 90px 6vw 90px 6vw;
    }
`
export const AboutDiv = styled.div`
    max-width: 1200px;
    margin: 0 auto;
`
export const AboutH = styled.h4`
    font-size: 28px;
    text-align: center;
`
export const AboutP = styled.p`
`