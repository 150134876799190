import styled from "styled-components";
import { FaTelegramPlane } from 'react-icons/fa';
import {screensize} from '../ScreenSize';

export const ContactStyledIconTelegram = styled(FaTelegramPlane)`
    width: 60px;
    height: 60px;

    @media screen and (max-width: ${screensize.phone}) {
        width: 40px;
        height: 40px;
    }
`

export const ContactsSection = styled.section`
    margin-top: 40px;
    padding: 60px 12vw 120px 12vw;
    // background-color: #f9f9f9;
    text-align: center;
`

export const ContactsH = styled.h5`
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
`

export const ContactsP = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 55px;
`

export const ContactsHh = styled.h6`
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
`

export const ContactsUl = styled.ul`
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    padding: 0;
`

export const ContactsLi = styled.li`
    margin-bottom: 15px;
    padding: 0 1em;
`

export const ContactsA = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    text-decoration: none;
    color: #333;
`

export const ContactsPc = styled.p`
  margin-left: 40px;
  background-color: #000; /* Черный фон */
  color: #fff; /* Белый текст */
  padding: 24px 48px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px; /* Закругленные края */
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Тень для объема */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

    @media screen and (max-width: ${screensize.phone}) {
        padding: 18px 32px;
    }
`

