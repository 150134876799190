import {StepsSection, StepsH, StepsUl, StepsLi, StepsP, StepsStrong} from './Steps.styled'
import React, { forwardRef } from 'react';

export const Steps = forwardRef((props, ref) => {
    return(
        <StepsSection ref={ref}>
            <StepsH>6 этапов</StepsH>
            <StepsUl>
                <StepsLi>
                    <StepsStrong>1</StepsStrong>
                    <StepsP>Отправка анкеты</StepsP>
                </StepsLi>
                <StepsLi>
                    <StepsStrong>2</StepsStrong>
                    <StepsP>Согласование тусовки</StepsP>
                </StepsLi>
                <StepsLi>
                    <StepsStrong>3</StepsStrong>
                    <StepsP>Покупка билетов</StepsP>
                </StepsLi>
                <StepsLi>
                    <StepsStrong>4</StepsStrong>
                    <StepsP>Прилет в ОАЭ</StepsP>
                </StepsLi>
                <StepsLi>
                    <StepsStrong>5</StepsStrong>
                    <StepsP>Тусовки на мероприятиях</StepsP>
                </StepsLi>
                <StepsLi>
                    <StepsStrong>6</StepsStrong>
                    <StepsP>Конец заезда и вылет домой</StepsP>
                </StepsLi>
            </StepsUl>
        </StepsSection>
)});