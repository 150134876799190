import { TitleSection, InfoSection, SectionMainSection, FigureSection, FigcaptionSection, BackImg} from './SectionOne.styled'

export const SectionOne = () => {
    return(
        <SectionMainSection>
              <FigureSection>
                <FigcaptionSection>
                    <TitleSection>Модельные тусовки, что нужно знать.</TitleSection>
                    <InfoSection>рекомендации, помощь, сопровождение, подробная информация</InfoSection>
                </FigcaptionSection>
            </FigureSection>
        </SectionMainSection>
    )
}