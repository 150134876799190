import { Urlsli, Urlsul, PartyHeadA, PartyStyledLink, PartyHeaderALast, PartyHeaderImg } from "./PartyHead.styled";
import { Link } from 'react-router-dom';
import { FaTelegramPlane } from "react-icons/fa";
import logo from '../../logo.svg'
import { screensize } from '../ScreenSize'

export const PartyHeader = ({scrollToSection, about, steps}) => {
    const isPhoneSize = window.innerWidth <= parseInt(screensize.phone);

    return(
        <Urlsul>
        {isPhoneSize ? (
            <>
            <PartyStyledLink to="/"><Urlsli>Главная</Urlsli></PartyStyledLink>
            <PartyHeaderImg src={logo}/>
            <Urlsli><PartyHeadA onClick={() => scrollToSection(steps, '/')}>6 шагов</PartyHeadA></Urlsli>
            <Urlsli><PartyHeadA onClick={() => scrollToSection(about, '/')}>Об агенстве</PartyHeadA></Urlsli>
            <Urlsli><FaTelegramPlane/><PartyHeaderALast href="https://t.me/Manaqer_Banni">Связаться с нами</PartyHeaderALast></Urlsli>
            </>
        ) : (
            <>
            <PartyStyledLink to="/"><Urlsli>Главная</Urlsli></PartyStyledLink>
            <Urlsli><PartyHeadA onClick={() => scrollToSection(about, '/')}>Об агенстве</PartyHeadA></Urlsli>
            <PartyHeaderImg src={logo}/>
            <Urlsli><PartyHeadA onClick={() => scrollToSection(steps, '/')}>6 шагов</PartyHeadA></Urlsli>
            <Urlsli><FaTelegramPlane/><PartyHeaderALast href="https://t.me/Manaqer_Banni">Связаться с нами</PartyHeaderALast></Urlsli>

            </>
        )}
    </Urlsul>

    )
}