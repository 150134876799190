import { TitleSection, InfoSection, SectionMainSection, FigureSection, FigcaptionSection, BackImg} from './PartySectionOne.styled'

export const PartySectionOne = () => {
    return(
        <SectionMainSection>
              <FigureSection>
                <FigcaptionSection>
                    <TitleSection>Частые вопросы про тусовки</TitleSection>
                    <InfoSection>условия тусовок, безопасность девушек, другие вопросы.</InfoSection>
                </FigcaptionSection>
            </FigureSection>
        </SectionMainSection>
    )
}