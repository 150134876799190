import styled from "styled-components";
import {screensize} from '../ScreenSize';
import { FaCheck } from "react-icons/fa";

export const QuestionsSection = styled.section`
    padding: 90px 12vw 0 12vw;

    @media screen and (max-width: ${screensize.laptop}) {
        padding: 70px 8vw 0 8vw;
    }

    @media screen and (max-width: ${screensize.phone}) {
        padding: 50px 6vw 0 6vw;
    }
`

export const QuestionsUl = styled.ul`
    padding: 0;
    margin: 0;
    
`

export const QuestionsLi = styled.li`
    display: flex;
    align-items: top;
    justify-content: left;
    list-style: none;
    padding: 60px 0 0 0;
`

export const QuestionsIcon = styled(FaCheck)`
    width: 60px;
    height: 30px;
    padding: 0 40px;


    @media screen and (max-width: ${screensize.laptop}) {
        padding: 0 20px;
        width: 40px;
        height: 60px;
    }

    @media screen and (max-width: ${screensize.phone}) {
        width: 30px;
        padding: 0 12px 0 0;
    }
`

export const QuestionsDiv = styled.div`
    width: 100%;
`

export const QuestionsH = styled.h5`
    font-size: 20px;
    line-height: 30px;
    padding: 0 0 20px 0;
    margin: 0;
`

export const QuestionsP = styled.p`
    padding: 0;
    margin: 0;
`

